import React from 'react'
import {graphql} from 'gatsby'
import SEO from '../components/seo'
import {PageProps} from 'gatsby'

const Privacy: React.FC = ({data}: any) => {
  const {markdownRemark} = data
  const {html} = markdownRemark
  return (
    <div className="term container max-w-screen-md mx-auto px-8 lg:flex lg:items-center mt-32">
      <SEO
        title="개인정보처리방침"
        description="에잇코즈의 개인정보처리방침입니다.."
      />
      <div dangerouslySetInnerHTML={{__html: html}} />
    </div>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: {slug: {eq: "/privacy"}}) {
      html
      #      frontmatter {
      #        date(formatString: "MMMM DD, YYYY")
      #        slug
      #        title
      #      }
    }
  }
`

export default Privacy
